<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/investor-relations.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block ir-block__top">
            <div class="page-breadcrumb width-master">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
                <InvestorRelationsNav></InvestorRelationsNav>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="ir-block__hd ir-block__basic">
                <div class="title">基本信息</div>
                <div class="content-detail">
                    <div class="basic-info">
                        <div class="basic-info__item">
                            <div class="basic-info__name">公司全称：</div>
                            <div class="basic-info__text">
                                广东雄塑科技集团股份有限公司
                            </div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">英文名称：</div>
                            <div class="basic-info__text">
                                Guangdong Xiongsu Technology Group Co., Ltd.
                            </div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">注册地址：</div>
                            <div class="basic-info__text">
                                广东省佛山市南海区九江镇龙高路敦根路段雄塑工业园
                            </div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">公司简称：</div>
                            <div class="basic-info__text">雄塑科技</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">股票代码：</div>
                            <div class="basic-info__text">300599</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">法定代表人：</div>
                            <div class="basic-info__text">黄淦雄</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">注册资本(万元)：</div>
                            <div class="basic-info__text">35,813.1567</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">主营业务：</div>
                            <div class="basic-info__text">
                                高性能高分子复合材料研发、生产与销售
                            </div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">上市时间：</div>
                            <div class="basic-info__text">2017-01-23</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">公司董秘：</div>
                            <div class="basic-info__text">何勇军</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">邮政编码：</div>
                            <div class="basic-info__text">528203</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">公司电话：</div>
                            <div class="basic-info__text">0757-81868066</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">公司传真：</div>
                            <div class="basic-info__text">0757-81868318</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">公司邮箱：</div>
                            <div class="basic-info__text">XS300599@126.com</div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">
                                指定信息披露报纸：
                            </div>
                            <div class="basic-info__text">
                                《中国证券报》《上海证券报》《证券时报》《证券日报》
                            </div>
                        </div>
                        <div class="basic-info__item">
                            <div class="basic-info__name">
                                指定信息披露网站：
                            </div>
                            <div class="basic-info__text">
                                www.cninfo.com.cn
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ir-block__hd ir-block__sotck-k">
                <div class="title">股票走势</div>
                <div class="content-detail">
                    <h4 class="stock-info-title">分时</h4>
                    <div class="stock-info-image">
                        <img
                            class="img-responsive stock-relation"
                            src="http://pifm.eastmoney.com/EM_Finance2014PictureInterface/Index.aspx?id=3005992&amp;imageType=r&amp;token=44c9d251add88e27b65ed86506f6e5da"
                        />
                    </div>
                    <h4 class="stock-info-title">日K线图</h4>
                    <div class="stock-info-image">
                        <img
                            class="img-responsive stock-relation"
                            src="http://pifm3.eastmoney.com/EM_Finance2014PictureInterface/Index.aspx?ID=3005992&amp;UnitWidth=-6&amp;imageType=KXL&amp;EF=&amp;Formula=RSI&amp;AT=&amp;&amp;type=&amp;token=44c9d251add88e27b65ed86506f6e5da&amp;_=0.08169838122058137"
                        />
                    </div>
                    <h4 class="stock-info-title">周K线图</h4>
                    <div class="stock-info-image">
                        <img
                            class="img-responsive stock-relation"
                            src="http://pifm.eastmoney.com/EM_Finance2014PictureInterface/Index.aspx?ID=3005992&amp;UnitWidth=-6&amp;imageType=KXL&amp;EF=&amp;Formula=RSI&amp;AT=&amp;&amp;type=W&amp;token=44c9d251add88e27b65ed86506f6e5da&amp;_=0.928881860455651"
                        />
                    </div>
                    <h4 class="stock-info-title">月K线图</h4>
                    <div class="stock-info-image">
                        <img
                            class="img-responsive stock-relation"
                            src="http://pifm.eastmoney.com/EM_Finance2014PictureInterface/Index.aspx?ID=3005992&amp;UnitWidth=-6&amp;imageType=KXL&amp;EF=&amp;Formula=RSI&amp;AT=&amp;&amp;type=M&amp;token=44c9d251add88e27b65ed86506f6e5da&amp;_=0.008530832293599522"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import InvestorRelationsNav from "@/components/InvestorRelationsNav.vue";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "IRSecurities",
    components: {
        InvestorRelationsNav,
    },
    data() {
        return {
            list: [],
        };
    },
};
</script>
<style lang="scss">
@import "~@/assets/style/investor-relations.scss";
</style>
